import * as React from "react"
import { SVGProps } from "react"

const ReportDataIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_904_3299)">
                <path d="M7.34375 13.125H8.65625V15.75H7.34375V13.125ZM10.625 11.8125H11.9375V15.75H10.625V11.8125ZM4.0625 9.1875H5.375V15.75H4.0625V9.1875Z" fill="currentColor" />
                <path d="M13.9062 3.28125H11.9375V2.625C11.9375 2.2769 11.7992 1.94306 11.5531 1.69692C11.3069 1.45078 10.9731 1.3125 10.625 1.3125H5.375C5.0269 1.3125 4.69306 1.45078 4.44692 1.69692C4.20078 1.94306 4.0625 2.2769 4.0625 2.625V3.28125H2.09375C1.74565 3.28125 1.41181 3.41953 1.16567 3.66567C0.919531 3.91181 0.78125 4.24565 0.78125 4.59375V18.375C0.78125 18.7231 0.919531 19.0569 1.16567 19.3031C1.41181 19.5492 1.74565 19.6875 2.09375 19.6875H13.9062C14.2543 19.6875 14.5882 19.5492 14.8343 19.3031C15.0805 19.0569 15.2188 18.7231 15.2188 18.375V4.59375C15.2188 4.24565 15.0805 3.91181 14.8343 3.66567C14.5882 3.41953 14.2543 3.28125 13.9062 3.28125ZM5.375 2.625H10.625V5.25H5.375V2.625ZM13.9062 18.375H2.09375V4.59375H4.0625V6.5625H11.9375V4.59375H13.9062V18.375Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_904_3299">
                    <rect width="21" height="21" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>

    )

}


export default ReportDataIcon