import * as React from "react"
import { SVGProps } from "react"

const DollarIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_904_8744)">
                <path d="M8.29911 9.95833C7.06696 9.65278 6.08929 9.20833 5.36607 8.625C4.64286 8.04167 4.28125 7.19444 4.28125 6.08333C4.28125 5.19444 4.5558 4.48611 5.10491 3.95833C5.65402 3.43056 6.37054 3.08333 7.25446 2.91667V1H9.82589V2.91667C10.7366 3.11111 11.4799 3.5 12.0558 4.08333C12.6317 4.66667 12.9464 5.41667 13 6.33333H11.1116C11.0045 5.08333 10.1473 4.45833 8.54018 4.45833C7.70982 4.45833 7.12054 4.625 6.77232 4.95833C6.42411 5.29167 6.25 5.65278 6.25 6.04167C6.25 6.51389 6.4308 6.93056 6.79241 7.29167C7.15402 7.65278 7.83036 7.95833 8.82143 8.20833C11.5 8.875 12.8393 10.0972 12.8393 11.875C12.8393 12.6806 12.558 13.3681 11.9955 13.9375C11.433 14.5069 10.7098 14.8889 9.82589 15.0833V17H7.25446V15.0417C6.39732 14.8194 5.73438 14.4028 5.26562 13.7917C4.79688 13.1806 4.54911 12.4722 4.52232 11.6667H6.41071C6.46429 12.9167 7.17411 13.5417 8.54018 13.5417C9.26339 13.5417 9.83259 13.3889 10.2478 13.0833C10.6629 12.7778 10.8705 12.375 10.8705 11.875C10.8705 11.4583 10.683 11.1042 10.308 10.8125C9.93304 10.5208 9.26339 10.2361 8.29911 9.95833Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_904_8744">
                    <rect width="9" height="16" fill="white" transform="matrix(-1 0 0 -1 13 17)" />
                </clipPath>
            </defs>
        </svg>
    )

}


export default DollarIcon