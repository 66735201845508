import * as React from "react"
import { SVGProps } from "react"

const ExclamationErrorIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 416 416"
            fill="none"
            {...props}
        >
            <path d="M208 0C93.31 0 0 93.31 0 208C0 322.69 93.31 416 208 416C322.69 416 416 322.69 416 208C416 93.31 322.69 0 208 0ZM208 319.91C204.044 319.91 200.178 318.737 196.889 316.539C193.6 314.342 191.036 311.218 189.522 307.564C188.009 303.909 187.613 299.888 188.384 296.008C189.156 292.129 191.061 288.565 193.858 285.768C196.655 282.971 200.219 281.066 204.098 280.294C207.978 279.523 211.999 279.919 215.654 281.432C219.308 282.946 222.432 285.51 224.629 288.799C226.827 292.088 228 295.954 228 299.91C228 305.214 225.893 310.301 222.142 314.052C218.391 317.803 213.304 319.91 208 319.91ZM229.72 118.76L223.98 240.76C223.98 245.003 222.294 249.073 219.294 252.074C216.293 255.074 212.223 256.76 207.98 256.76C203.737 256.76 199.667 255.074 196.666 252.074C193.666 249.073 191.98 245.003 191.98 240.76L186.24 118.82V118.77C186.114 115.839 186.583 112.913 187.618 110.169C188.652 107.424 190.232 104.917 192.261 102.798C194.291 100.68 196.728 98.9945 199.426 97.8429C202.123 96.6914 205.027 96.0977 207.96 96.0977C210.893 96.0977 213.797 96.6914 216.494 97.8429C219.192 98.9945 221.629 100.68 223.659 102.798C225.688 104.917 227.268 107.424 228.302 110.169C229.337 112.913 229.806 115.839 229.68 118.77L229.72 118.76Z" fill="currentColor" />
        </svg>
    )

}


export default ExclamationErrorIcon