import * as React from "react"
import { SVGProps } from "react"

const CalendarIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
    <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_1381_1762)">
            <path d="M14.875 1.41667H12.75V0H11.3333V1.41667H5.66667V0H4.25V1.41667H2.125C1.56141 1.41667 1.02091 1.64055 0.622398 2.03906C0.223883 2.43758 0 2.97808 0 3.54167L0 17H17V3.54167C17 2.97808 16.7761 2.43758 16.3776 2.03906C15.9791 1.64055 15.4386 1.41667 14.875 1.41667ZM1.41667 3.54167C1.41667 3.3538 1.49129 3.17364 1.62413 3.0408C1.75697 2.90796 1.93714 2.83333 2.125 2.83333H14.875C15.0629 2.83333 15.243 2.90796 15.3759 3.0408C15.5087 3.17364 15.5833 3.3538 15.5833 3.54167V5.66667H1.41667V3.54167ZM1.41667 15.5833V7.08333H15.5833V15.5833H1.41667Z" fill="currentColor"/>
            <path d="M6 8H3V11H6V8Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_1381_1762">
                <rect width="17" height="17" fill="white"/>
            </clipPath>
        </defs>
        </svg>
    )

}


export default CalendarIcon